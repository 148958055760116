import { EventEmitter, Injectable } from '@angular/core';
import { NavUtilsV1 } from 'src/app/core/navegacao/services/nav-utils-v1';
import { MenuItem } from 'src/app/layouts/horizontaltopbar/menu.model';

@Injectable({
  providedIn: 'root'
})
export class NavCicloDeVidaEvent {
 /**
   * eventemitter publico
   */
 public eventMenuItemsCicloDeVida = new EventEmitter<MenuItem[]>();

 /**
  * função pública de publicação dos itens de menu colaboradores
  */
 public async menuCicloDeVidaSolicitado(menu: MenuItem[]) {
   let menuItemsCicloDeVida: MenuItem[] = [];

   menuItemsCicloDeVida = menu;

   NavUtilsV1.navItensMoment(menuItemsCicloDeVida);

   this.eventMenuItemsCicloDeVida.emit(menuItemsCicloDeVida);
 }
}
