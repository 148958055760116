import { EventEmitter, Injectable } from "@angular/core";
import { MENU_ESCRITORIOS } from "../menu/menu-escritorios";
import { MenuItem } from "src/app/core/navegacao/models/menu.model";
import { NavUtilsV1 } from "src/app/core/navegacao/services/nav-utils-v1";

@Injectable({
  providedIn: 'root'
})
export class NavEscritoriosEvent {

  /**
   * eventemitter publico
   */
  public eventMenuItensEscritorios = new EventEmitter<MenuItem[]>();

  /**
   * função pública de publicação dos itens de menu colaboradores
   */
  public async menuEscritoriosSolicitado(
    _rulesColaborador: any
  ) {
    let menuItensColaboradores: MenuItem[] = [];

    menuItensColaboradores = MENU_ESCRITORIOS;
    // menuItensColaboradores?.forEach(
    //   (element: MenuItemV1Model) => {
    //     if (element.colaborador) {
    //       /** pode acessar iten de menu: "Outros contas" */
    //       element.id == 2 ? ( // Contas Web
    //         element.allowed = _rulesColaborador.isContasWebMenu,
    //         _rulesColaborador.isContasWebMenu ? (
    //           element.hsubitens?.length != 0 ? (
    //             element.hsubitens?.forEach(
    //               (subitens) => {
    //                 subitens.id == 3 ?  // Lista de contas web
    //                   subitens.allowed = _rulesColaborador.contasWebMenu.isListaContasWeb : null;
    //                 subitens.id == 4 ?  // Perfis permissões
    //                   subitens.allowed = _rulesColaborador.contasWebMenu.isPerfisPermissoes : null;
    //                 subitens.id == 5 ?  // Novo perfil
    //                   subitens.allowed = _rulesColaborador.contasWebMenu.isNovoPerfil : null;
    //               }
    //             )
    //           ) : null
    //         ) : null
    //       ) : null;
    //     }
    //   }
    // );

    NavUtilsV1.navItensMoment(menuItensColaboradores);

    this.eventMenuItensEscritorios.emit(menuItensColaboradores);
  }
}
