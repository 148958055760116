import { MenuItem } from "src/app/core/navegacao/models/menu.model";

export const MENU_GERENTE: MenuItem[] = [
  {
    id: 1,
    isVertical: true,
    label: 'Menu de navegação',
    isTitle: true
  },
  {
    id: 2,
    isVertical: true,
    disabled: false,
    allowed: true,
    label: 'Resumo',
    icon: 'mdi mdi-view-dashboard-outline',
    link: '/gerente/resumo-gerente'
  },
  {
    id: 3,
    isVertical: true,
    disabled: false,
    allowed: true,
    label: 'Parâmetros',
    icon: 'fas fa-tools',
    ishidden: true,
    vSubItems: [
      {
        id: 4,
        disabled: false,
        allowed: true,
        label: 'Códigos',
        link: '/gerente/lifecycle-config/codigos',
        icon: 'fas fa-file',
        parentId: 3
      },
      {
        id: 5,
        disabled: false,
        allowed: true,
        label: 'Fluxos RPA',
        link: '/gerente/lifecycle-config/fluxos-rpa',
        icon: 'bx bx-cog',
        parentId: 3
      },
    ]
  },
  // {
  //   id: 2,
  //   isVertical: true,
  //   disabled: true,
  //   allowed: true,
  //   label: 'Perfis de acesso',
  //   icon: 'fa fas fa-users-cog',
  //   ishidden: true,
  //   vSubItems: [
  //     {
  //       id: 3,
  //       disabled: true,
  //       allowed: true,
  //       label: 'Meu perfil',
  //       link: '#',
  //       icon: 'fa fas fa-id-card-alt',
  //       parentId: 2
  //     },
  //     {
  //       id: 4,
  //       disabled: true,
  //       allowed: true,
  //       label: 'Perfis Colaboradores',
  //       link: '#',
  //       icon: 'fa fas fa-house-user',
  //       parentId: 2
  //     },
  //   ]
  // },
  // {
  //   id: 5,
  //   isVertical: true,
  //   disabled: true,
  //   allowed: true,
  //   label: 'Integração Domínio Sistemas',
  //   icon: 'fa fas fa-cogs',
  //   ishidden: true,
  //   vSubItems: [
  //     {
  //       id: 6,
  //       disabled: true,
  //       allowed: true,
  //       label: 'Contadore(s)',
  //       link: 'gerente/integracao-ds/contadores',
  //       icon: 'bx bxs-cog',
  //       parentId: 5
  //     },
  //     {
  //       id: 7,
  //       disabled: true,
  //       allowed: true,
  //       label: 'Escritório(s)',
  //       link: 'gerente/integracao-ds/escritorios',
  //       icon: 'bx bxs-cog',
  //       parentId: 5
  //     },
  //     {
  //       id: 8,
  //       disabled: true,
  //       allowed: true,
  //       label: 'Colaboradores',
  //       link: 'gerente/integracao-ds/colaboradores',
  //       icon: 'bx bxs-cog',
  //       parentId: 5
  //     },
  //     {
  //       id: 9,
  //       disabled: true,
  //       allowed: true,
  //       label: 'Empresas',
  //       link: 'gerente/integracao-ds/empresas',
  //       icon: 'bx bxs-cog',
  //       parentId: 5
  //     },
  //   ]
  // },
  // {
  //   id: 10,
  //   isVertical: true,
  //   disabled: true,
  //   allowed: true,
  //   label: 'Cargos e Departamentos',
  //   icon: 'fa fas fa-cogs',
  //   vSubItems: [
  //     {
  //       id: 11,
  //       disabled: true,
  //       allowed: true,
  //       label: 'Departamentos',
  //       link: '/gerente/integracao-ds/organograma/departamentos',
  //       icon: 'bx bxs-cog',
  //       parentId: 10
  //     },
  //     {
  //       id: 12,
  //       disabled: true,
  //       allowed: true,
  //       label: 'Cargos',
  //       link: '/gerente/integracao-ds/organograma/cargos',
  //       icon: 'bx bxs-cog',
  //       parentId: 10
  //     }
  //   ]
  // }
];
