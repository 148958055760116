import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule, NgbTooltipModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';

import { WidgetModule } from './widget/widget.module';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NaoHaDadosAVisualizarComponent } from './nao-ha-dados-a-visualizar/nao-ha-dados-a-visualizar.component';
import { Page404Component } from './page404/page404.component';
import { Page500Component } from './page500/page500.component';
import { SharedRoutingModule } from './shared.routing';
import { CnpjPipe } from './utils/cnpj.pipe';
import { CpfPipe } from './utils/cpf.pipe';
import { CpfCnpjPipe } from './utils/cpf-cnpj.pipe';

@NgModule({
  declarations: [
    PagetitleComponent,
    MaintenanceComponent,
    ComingsoonComponent,
    Page500Component,
    Page404Component,
    NaoHaDadosAVisualizarComponent,
    CnpjPipe,
    CpfPipe,
  ],
  imports: [
    CommonModule,
    WidgetModule,
    NgbPopoverModule,
    NgbTooltipModule,
    SharedRoutingModule,
    NgxPaginationModule,
    NgbTooltip,
    ToastrModule.forRoot()
  ],
  exports: [
    PagetitleComponent,
    WidgetModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgxPaginationModule,
    NaoHaDadosAVisualizarComponent,
    CnpjPipe,
    CpfPipe,
    NgbTooltip,
    ToastrModule,
  ]
})
export class SharedModule { }
