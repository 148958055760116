import { MenuItem } from "../models/menu.model";

export class NavUtilsV1 {

  // public static set(
  //   _rulesAccount: any
  // ) {
  //   /**
  //    * Itens de navegação referente ao menu Colaboradores
  //    * caso o usuário authenticado tenha permissão de acesso a este iten de menu
  //    */
  //   _rulesAccount.isRulesColaborador ? (
  //     /** Gravando no local storage as permissões do menu colaborador
  //      * e itens de janelas com o nome "navitensColaboradores"
  //      */
  //     localStorage.setItem('navitensEscritorios',
  //       /** Criptografando os itens de menu e permissões */
  //       btoa(
  //         /** convertendo o JSON em texto comun */
  //         JSON.stringify(
  //           /** dados a serem armazenados em local storage */
  //           _rulesAccount._rulesColaborador
  //         )
  //       )
  //     )
  //   ) : null;
  //   /** os itens abaixo segue os mesmo parametros do itens acim adocumentado. */

  //   /** Rules RESERVA */
  //   _rulesAccount.isRulesReservas ? (
  //     localStorage.setItem('navitensReservas',
  //       btoa(
  //         JSON.stringify(
  //           _rulesAccount._rulesReservas
  //         )
  //       )
  //     )
  //   ) : null;

  //   /** Rules AGENCIAS */
  //   _rulesAccount.isRulesAgencias ? (
  //     localStorage.setItem('navitensAgencias',
  //       btoa(
  //         JSON.stringify(
  //           _rulesAccount._rulesAgencias
  //         )
  //       )
  //     )
  //   ) : null;

  //   /** Rules PARCEIROS */
  //   _rulesAccount.isRulesParceiros ? (
  //     localStorage.setItem('navitensParceiros',
  //       btoa(
  //         JSON.stringify(
  //           _rulesAccount._rulesParceiros
  //         )
  //       )
  //     )
  //   ) : null;

  //   /** Rules GATEWAY */
  //   _rulesAccount.isRulesGateway ? (
  //     localStorage.setItem('navitensGateway',
  //       btoa(
  //         JSON.stringify(
  //           _rulesAccount._rulesGateway
  //         )
  //       )
  //     )
  //   ) : null;

  //   /** Rules PORTAL */
  //   _rulesAccount.isRulesPortal ? (
  //     localStorage.setItem('navitensPortal',
  //       btoa(
  //         JSON.stringify(
  //           _rulesAccount._rulesPortal
  //         )
  //       )
  //     )
  //   ) : null;

  //   /** Rules CONFIGURACOES */
  //   _rulesAccount.isRulesConfig ? (
  //     localStorage.setItem('navitensConfig',
  //       btoa(
  //         JSON.stringify(
  //           _rulesAccount._rulesConfig
  //         )
  //       )
  //     )
  //   ) : null;
  // }

  /**
   * Return 'navitensColaboradores' contendo as permissões
   * da rulesColaboradores
   */
  public static getNavItensRulesEscritorio(): any | null {
    /** Criando a variavel data contendo os dados após converte de
     * texto normal paa JASON
     */
    const data = JSON.parse(
      /** decriptografando o iten salvo no localstorage */
      atob(
        /** dados a serem coletados no local storage */
        localStorage.getItem('navitensEscritorios') as string
      )
    );
    return data ? data : false;
  }
  /** os itens abaixo segue os mesmo parametros do itens acim adocumentado. */

  /** Rules RESERVA */
  public static getNavItensRulesReservas(): any | null {
    const data = JSON.parse(atob(
      localStorage.getItem('navitensReservas') as string
    ));
    return data ? data : null;
  }

  /** Rules AGENCIAS */
  public static getNavItensRulesAgencias(): any | null {
    const data = JSON.parse(atob(
      localStorage.getItem('navitensAgencias') as string
    ));
    return data ? data : null;
  }

  /** Rules PARCEIROS */
  public static getNavItensRulesParceiros(): any | null {
    const data = JSON.parse(atob(
      localStorage.getItem('navitensParceiros') as string
    ));
    return data ? data : null;
  }

  /** Rules GATEWAY */
  public static getNavItensRulesGateway(): any | null {
    const data = JSON.parse(atob(
      localStorage.getItem('navitensGateway') as string
    ));
    return data ? data : null;
  }

  /** Rules PORTAL */
  public static getNavItensRulesPortal(): any | null {
    const data = JSON.parse(atob(
      localStorage.getItem('navitensPortal') as string
    ));
    return data ? data : null;
  }

  /** Rules CONFIGURAÇÕES */
  public static getNavItensRulesConfig(): any | null {
    const data = JSON.parse(atob(
      localStorage.getItem('navitensConfig') as string
    ));
    return data ? data : null;
  }

  /** set local storage o menu de navegação ativado no momento */
  public static navItensMoment(navIten: MenuItem[]) {
    localStorage.setItem(
      'navMoment',
      btoa(
        JSON.stringify(
          navIten
        )
      )
    );
  }

  /** get local storage o menu de navegação ativado no momento */
  public static getNavMoment(): any | null {
    const data = JSON.parse(atob(
      localStorage.getItem('navMoment') as string
    ));
    return data ? data : null;
  }

  /**
   * função para realizar a remoção do item 'navMoment' do localStorage
   */
  public static clearNavMoment(){
    localStorage.removeItem('navMoment');
  }
}
