export const environment = {
  production: false,

  /**
   * Dados para comunicação com api ext ctb
   */
  api: 'https://apictb.epigrafo.com', //no dashboard, a autenticação na api é feita pelo Authorization Bearer
  accessTokenFixo: 'Y3RiX2dBZmgwUUF2WG9nRlNqQXJUbHhtenpFa2YyOUk1ekJBekR4V1hWOFJQOWFrQ3VUdThEZnZXNEdHdzR5MkJsVFFIWUpCaHBNNjJ3T1VEU3VLWnNpeFpRZTFTSVlkOHk3c0llWUI=',

  /**
   * Dados para comunicação com api asaas
   */
  apiAsaas: 'https://asaas1.epigrafo.com',
  accountaccess: 'R3k0Wmk2THExU3c3TG01TWQyR3Q0VXM3UW42U3ExRWI3VWk4R3UxWXoyRnM4UWU0VHA1WnI2V3kyUXU5SWc0TXo0U2UxRnk2WGs3WWIxQmc1VnQ5QXA2Vm0yWWU3THE2UGQzUw==',
  
  /**
   * Dados para comunicação api serpro
   */
  apiSERPRO: 'http://guiademo.epigrafo.com.br',
  accesstoken: 'UWE3Tm01QXM5U2E0VXY5TnU3WGI3WHc3RWM2U2YxSXgzTmE5S3A2R3E1UHg2TWUzTnkzSHA5RmQyWWI5Sno2TXg0Tms3QXI3VGg5THI3RmE4U3M2QXAxUmI0QmU4UXExQ2oyVQ==',
  
  /**
   * Dados para comunicação api conversor
   */
  apiConversor: 'https://demoapiofx.epigrafo.com.br',
  accesstokenConversor: 'ZXBnX2pJVndVd0tFdzhrOVBKTW9LWHlQdTFIakZtT01ROUpGdkp5R2loTGJLTExrM3VrbWQ2U2hPOWpoR0hjUU9LUGp0eTNqaVRQU1pUdnA3Y1dTSFNVYkJWclBHSFFtQ1AxREVXTXojIw=='
};
