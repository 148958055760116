import { ChartOptions } from './crypto.model';

/**
 * BTC Chart
 */
const BTCChart: ChartOptions = {
  series: [{
    data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14]
  }],
  chart: {
    type: 'area',
    width: 120,
    height: 45,
    sparkline: {
      enabled: true
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05
    },
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  colors: ['#0576b9'],
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

/**
 * ETH Chart
 */
const ETHChart: ChartOptions = {
  series: [{
    data: [65, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14]
  }],
  chart: {
    type: 'area',
    width: 120,
    height: 45,
    sparkline: {
      enabled: true
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05
    },
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#f56e50'],
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

/**
 * LTC Chart
 */
const LTCChart: ChartOptions = {
  series: [{
    data: [12, 75, 2, 47, 42, 15, 47, 75, 65, 19, 14]
  }],
  chart: {
    type: 'area',
    width: 120,
    height: 45,
    sparkline: {
      enabled: true
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05
    },
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  colors: ['#0576b9'],
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

/**
 * Available Balance Chart
 */
const balanceChart: ChartOptions = {
  series: [{
    data: [25, 40, 31, 42, 51, 44, 36, 50, 37, 29, 25, 40, 31, 42, 51,]
  }],
  fill: {
    colors: ['#0576b9'],
  },
  chart: {
    type: 'bar',
    height: 82,
    width: 310,
    sparkline: {
      enabled: true
    },
  },
  plotOptions: {
    bar: {
      columnWidth: '60%',
      borderRadius: 50,
    }
  },

  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

/**
 * Market Overview Chart
 */
const marketChart: ChartOptions = {
  series: [{
    name: 'Profit',
    data: [{
      x: '2:00PM',
      y: [450, 850]
    }, {
      x: '2:30PM',
      y: [370, 750]
    }, {
      x: '3:00PM',
      y: [550, 800]
    }, {
      x: '3:30PM',
      y: [500, 900]
    }, {
      x: '4:00PM',
      y: [400, 750]
    }, {
      x: '4:30PM',
      y: [550, 800]
    }, {
      x: '5:00PM',
      y: [500, 800]
    }, {
      x: '5:30PM',
      y: [400, 700]
    }, {
      x: '6:00PM',
      y: [500, 750]
    }, {
      x: '6:30PM',
      y: [380, 750]
    }, {
      x: '7:00PM',
      y: [550, 850]
    }, {
      x: '7:30PM',
      y: [600, 950]
    }, {
      x: '8:00PM',
      y: [500, 900]
    }]
  },
  {
    name: 'Loss',
    data: [{
      x: '2:00PM',
      y: [500, 750]
    }, {
      x: '2:30PM',
      y: [550, 900]
    }, {
      x: '3:00PM',
      y: [500, 680]
    }, {
      x: '3:30PM',
      y: [400, 800]
    }, {
      x: '4:00PM',
      y: [550, 850]
    }, {
      x: '4:30PM',
      y: [650, 750]
    }, {
      x: '5:00PM',
      y: [650, 850]
    }, {
      x: '5:30PM',
      y: [500, 750]
    }, {
      x: '6:00PM',
      y: [600, 850]
    }, {
      x: '6:30PM',
      y: [450, 900]
    }, {
      x: '7:00PM',
      y: [370, 750]
    }, {
      x: '7:30PM',
      y: [550, 650]
    }, {
      x: '8:00PM',
      y: [700, 950]
    }]
  }],
  stroke: {
    width: 2 ,
    show: true,
    colors: ["#fff"]
  },

  chart: {
    type: 'rangeBar',
    height: 400,
    toolbar: {
      show: false,
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '42%',
      horizontal: false
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false,
  },
  colors: ['#0576b9', '#f56e50'],
};

/**
 * Current Statistic Chart
 */
const CurrentChart: ChartOptions = {
  series: [40, 60, 40, 25],
  chart: {
    height: 248,
    type: 'donut',
  },
  labels: ["Invest", "Income", "Spends", "Installment"],
  plotOptions: {
    pie: {
      donut: {
        size: '75%',
      }
    }
  },
  legend: {
    show: false,
  },
  colors: ['#2cb57e', '#0576b9', '#f5bd58', '#f56e50'],
};

/**
 * Dai Chart
 */
const DaiChart: ChartOptions = {
  series: [70],
  chart: {
    height: 150,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: '50%',
      }
      ,
      dataLabels: {
        name: {
          show: false,
        }
        ,
        value: {
          show: true, fontSize: '14px', offsetY: 5,
        },
      }
    },
  },
  colors: ['#2cb57e'],
};

/**
 * Cryptocurrency Prices by Market Cap Charts
 */
const Chart1: ChartOptions = {
  series: [{
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
  }],
  chart: {
    type: 'line',
    width: 120,
    height: 30,
    sparkline: {
      enabled: true
    }
  },
  colors: ['#f56e50'],
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

// Chart 2
const Chart2: ChartOptions = {
  series: [{
    data: [50, 15, 35, 62, 23, 56, 44, 12, 36, 9, 54]
  }],
  chart: {
    type: 'line',
    width: 120,
    height: 30,
    sparkline: {
      enabled: true
    }

  },
  colors: ['#2cb57e'],
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

// Chart 3
const Chart3: ChartOptions = {
  series: [{
    data: [25, 35, 35, 89, 63, 25, 44, 12, 36, 9, 54]
  }],
  chart: {
    type: 'line',
    width: 120,
    height: 30,
    sparkline: {
      enabled: true
    }

  },
  colors: ['#2cb57e'],
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

// Chart 4
const Chart4: ChartOptions = {
  series: [{
    data: [50, 15, 35, 34, 23, 56, 65, 41, 36, 41, 32]
  }],
  chart: {
    type: 'line',
    width: 120,
    height: 30,
    sparkline: {
      enabled: true
    }

  },
  colors: ['#f56e50'],
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

// Chart 5
const Chart5: ChartOptions = {
  series: [{
    data: [45, 53, 24, 89, 63, 60, 36, 50, 36, 32, 54]
  }],
  chart: {
    type: 'line',
    width: 120,
    height: 30,
    sparkline: {
      enabled: true
    }
  },
  colors: ['#f56e50'],
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};

// Chart 6
const Chart6: ChartOptions = {
  series: [{
    data: [50, 15, 35, 62, 23, 56, 44, 12, 36, 9, 54]
  }],
  chart: {
    type: 'line',
    width: 120,
    height: 30,
    sparkline: {
      enabled: true
    }

  },
  colors: ['#2cb57e'],
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: false
    }
  }
};


export { BTCChart, ETHChart, LTCChart, balanceChart, marketChart, CurrentChart, DaiChart, Chart1, Chart2, Chart3, Chart4, Chart5, Chart6 };
