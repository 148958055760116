import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SecurityUtil } from 'src/app/core/utils/security.util';
import { UserAuthV1Model } from 'src/app/auth/models/user-auth-v1.model';
import { NavEscritoriosEvent } from 'src/app/routes/escritorios/nav-escritorio/events/nav-escritorios.event';
import { MENU_CICLO_DE_VIDA } from 'src/app/routes/ciclo-de-vida/nav-ciclo-de-vida/menu/menu-ciclo-de-vida';
import { NavCicloDeVidaEvent } from 'src/app/routes/ciclo-de-vida/nav-ciclo-de-vida/events/nav-ciclo-de-vida.event';
import { NavGerenteEvent } from 'src/app/routes/gerente/nav-gerente/events/nav-gerente.event';
import { MENU_GERENTE } from 'src/app/routes/gerente/nav-gerente/menu/menu-gerente';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  userLogado!: UserAuthV1Model | null;

  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;

  constructor(
    private route: Router,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public _cookiesService: CookieService,
    private readonly navEscritorio: NavEscritoriosEvent,
    private readonly navGerente: NavGerenteEvent,
    private readonly navCicloDeVida: NavCicloDeVidaEvent
  ) {
    /**
     * Coleta dados do usuário authenticado
     */
    this.userLogado = SecurityUtil.getAccount();
  }
  navMenu(navMenu: string) {
    switch (navMenu) {
      case 'gerente':
        this.navGerente.menuGerenteSolicitado(MENU_GERENTE);
        break;
      case 'ciclo-de-vida':
        this.navCicloDeVida.menuCicloDeVidaSolicitado(MENU_CICLO_DE_VIDA);
        break;
      default:
        this.navGerente.menuGerenteSolicitado(MENU_GERENTE);
        break;
    }
  }

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit(): void {

    this.element = document.documentElement;
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    SecurityUtil.clear();
    this.route.navigate([`/auth/login`])
  }

}
