import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './shared/page404/page404.component';

const routes: Routes = [
  /** AUTHENTICAÇÃO */
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule)
  },

  { path: '', redirectTo: '/pages/dashboard', pathMatch: 'full' },

  /**
   * Pages e destinados a conteudos globais, propagandas, divulgação de módulos, e outros
   */
  {
    path: 'pages',
    component: LayoutComponent,
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule)
  },

  //Módulo Gerente
  {
    path: 'gerente',
    component: LayoutComponent,
    loadChildren: () => import('./routes/gerente/gerente.module')
      .then(m => m.GerenteModule)
  },

  //Módulo Ciclo de Vida
  {
    path: 'lifecycle',
    component: LayoutComponent,
    loadChildren: () => import('./routes/ciclo-de-vida/ciclo-de-vida.module')
    .then(m => m.CicloDeVidaModule)
  },

  /** shared components */
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
