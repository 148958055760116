import { environment } from "src/environments/environment.demo";

export const EPIGRAFO_API = environment.api;

/**URL para acesso à API Asaas*/
export const API_ASAAS = environment.apiAsaas;

/**Account Token para permissão de acesso à API Asaas */
export const accountaccess = environment.accountaccess;

/**URL para acesso à API Serpro */
export const API_SERPRO = environment.apiSERPRO;

/**Account Token para permissão de acesso à API Serpro */
export const accesstoken = environment.accesstoken