<div class="account-pages">
    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <div class="text-center mb-5">
                    <a routerLink="/" class="auth-logo">
                        <img src="assets/images/logo-dark.png" alt="" height="28" class="auth-logo-dark">
                        <img src="assets/images/logo-light.png" alt="" height="28" class="auth-logo-light">
                    </a>
                    <p class="font-size-15 text-muted mt-3">Responsive <strong>Bootstrap 5</strong> Admin Dashboard</p>
                </div>
                <div class="card overflow-hidden">
                    <div class="row g-0">

                        <div class="col-lg-6  order-2 order-lg-1">
                            <div class="p-lg-5 p-4">
                                <h5>Let's get started with Samply</h5>
                                <p class="font-size-15 text-muted">It will be as simple as in fact it will be
                                    occidental.</p>

                                <div class="mt-5">
                                    <div inputDate="2024/12/31" class="counter-number ico-countdown">
                                        <div class="coming-box">{{_days}}<span>Days</span>
                                        </div>
                                        <div class="coming-box">{{_hours}}<span>Hours</span>
                                        </div>
                                        <div class="coming-box">{{_minutes}}<span>Minutes</span>
                                        </div>
                                        <div class="coming-box">{{_seconds}}<span>Seconds</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-section mt-5">
                                    <div class="row">
                                        <div class="col">
                                            <div class="position-relative">
                                                <input type="email" class="form-control"
                                                    placeholder="Enter email address...">
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <button type="submit"
                                                class="btn btn-primary w-md waves-effect waves-light">Subscribe</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">
                            <div class="p-lg-5 p-4 bg h-100">

                                <div class="comingsoon-img">
                                    <img src="assets/images/coming-soon-img.png" alt=""
                                        class="img-fluid mx-auto d-block">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>