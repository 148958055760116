export const environment = {
  production: false,

  /**
   * Dados para comunicação api ext ctb
   */
  api: 'http://localhost:3033', //no dashboard, a autenticação na api é feita pelo Authorization Bearer
  accessTokenFixo: 'Y3RiX3h3d0xTdXpZZmZ4RTlhRVVkOTllaEJtN205OUw0WmQ5NHpvVXJZaDh4SUxZUEp0OTI2QllWS2FYT0xkMUVXNGg2Z3VUOTlWYWxQYmhJbERhdXc5Q2VlRzd5MUpaRHRHekpNMkY=',

  /**
   * Dados para comunicação api asaas
   */
  apiAsaas: 'http://localhost:3053',
  accountaccess: 'R3k0Wmk2THExU3c3TG01TWQyR3Q0VXM3UW42U3ExRWI3VWk4R3UxWXoyRnM4UWU0VHA1WnI2V3kyUXU5SWc0TXo0U2UxRnk2WGs3WWIxQmc1VnQ5QXA2Vm0yWWU3THE2UGQzUw==',

  /**
   * Dados para comunicação api serpro
   */
  apiSERPRO: 'http://localhost:3083',
  accesstoken: 'NEpua1k0R0dFYzZldmdVd0VOS1RXdXF3dnhzb3VlNmhtTXJrZ2V6RWtGREUzM3JwRUtYYlFVY2VNdGJuZDR4RjRYTjNSS01VNDVuZWZtNjc5TGQ1N1g5RlBHZjdWMnZoM0hidQ==',

  /**
   * Dados para comunicação api conversor
   */
  apiConversor: 'http://localhost:3024',
  accesstokenConversor: 'ZXBnX3B4YWdKdHRBVER0YkdMQ0JXY0JVRnlHSHZHUEd5eFl5NDU2OWQzZnB6ZFd2NXl2U1F5dGduNDlCVFBacUQ0VDU1elg5M1N4UU1tODRuSmhxVFFmQTZxTHpEQnU4OEMzNUt4RjIjIw=='
};
