import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountUpModule } from 'ngx-countup';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbDropdownModule, NgbAccordionModule, NgbNavModule, NgbProgressbarModule, NgbTooltipModule, NgbPopoverModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';

// Routings
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages.routing';

// Modules
import { WidgetModule } from '../shared/widget/widget.module';

// Components
import { DashboardsComponent } from './dashboard/dashboards/dashboards.component';
import { StarterComponent } from './starter/starter.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SimplebarAngularModule } from 'simplebar-angular';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CryptoComponent } from './dashboard/crypto/crypto.component';

@NgModule({
  declarations: [
    DashboardsComponent,
    StarterComponent,
    CryptoComponent
  ],
  imports: [
    CommonModule,
    WidgetModule,
    PagesRoutingModule,
    ScrollToModule.forRoot(),
    NgApexchartsModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbPopoverModule,
    CountUpModule,
    SharedModule,
    GoogleMapsModule,
    SimplebarAngularModule,
    LeafletModule,
    SlickCarouselModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
