<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="23">
          </span>
        </a>

        <a routerLink="" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-light.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="23">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 vertinav-toggle header-item waves-effect"
        id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <button type="button" class="btn btn-sm px-3 font-size-16 horinav-toggle header-item waves-effect waves-light"
        data-bs-toggle="collapse" data-bs-target="#topnav-menu-content" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <button type="button" class="btn header-item ps-0" href="javascript: void(0);" 
        (click)="navMenu('')" [routerLink]="['/']">
        <i class='bx bx-home bx-sm'></i>
      </button>

      <button type="button" class="btn header-item ps-0"  ngbTooltip="Módulo Gerente"
        href="javascript: void(0);" (click)="navMenu('gerente')" [routerLink]="[ '/gerente/resumo-gerente' ]">
        <i class='fas fa-user-cog bx-sm'></i>
      </button>

      <button type="button" class="btn header-item ps-0"  ngbTooltip="Módulo Ciclo de Vida"
        href="javascript: void(0);" (click)="navMenu('ciclo-de-vida')" [routerLink]="[ '/lifecycle/resumo-ciclo-de-vida' ]">
        <i class='bx bx-sync bx-sm'></i>
      </button>
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item waves-effect" ngbDropdownToggle id="page-header-user-dropdown"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" src="{{userLogado?._avatar?.url}}" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{userLogado?.apelido}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <h6 class="dropdown-header">{{ 'Bem vindo'}} <strong>{{ userLogado?.apelido}}</strong></h6>
          <a class="dropdown-item" [routerLink]="[ '/colaboradores/detalhes-do-perfil' ]"><i
              class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span class="align-middle"
              key="t-profile">Meu perfil</span></a>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
              class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span class="align-middle"
              key="t-logout">Sair</span></a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->

    </div>
  </div>
</header>
