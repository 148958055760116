<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box d-sm-flex align-items-center justify-content-between card flex-sm-row border-0">
      @if(!title){
        <div class="page-title-left">
          @for(item of toolbar;track $index){
          <i class="cursor-pointer nav-item text-muted fs-4 ps-3"
           [ngClass]="item.icon" [ngbTooltip]="item.tooltip" (click)="item.funcao()"></i>
          }
        </div>
      }@else{
        <h4 class="mb-sm-0 font-size-16 fw-semibold">{{ title }}</h4>
      }
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          @for(item of breadcrumbItems;track $index){
          <li class="breadcrumb-item" [ngClass]="{'active': item.active == true}">
            {{ item.label }}
          </li>
          }
        </ol>
      </div>
    </div>
  </div>
</div>