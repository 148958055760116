import { EventEmitter, Injectable } from "@angular/core";
import { MenuItem } from "src/app/core/navegacao/models/menu.model";
import { NavUtilsV1 } from "src/app/core/navegacao/services/nav-utils-v1";

@Injectable({
  providedIn: 'root'
})
export class NavGerenteEvent {

  /**
   * eventemitter publico
   */
  public eventMenuItensGerente = new EventEmitter<MenuItem[]>();

  /**
   * função pública de publicação dos itens de menu colaboradores
   */
  public async menuGerenteSolicitado(menu: MenuItem[]) {
    let menuItensGerente: MenuItem[] = [];

    menuItensGerente = menu;

    NavUtilsV1.navItensMoment(menuItensGerente);

    this.eventMenuItensGerente.emit(menuItensGerente);
  }
}
