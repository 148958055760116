<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
  <div data-simplebar class="h-100">
    <ngx-simplebar style="max-height: 100vh">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          @for(item of menuItems;track $index){
          @if(item.allowed){
          @if(item.isTitle){
          <li class="menu-title">{{ item.label }}</li>
          }@else {
          <li>
            @if(hasItems(item)){
            <a href="javascript:void(0);" class="is-parent"
              [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
              @if(item.icon){<i class="{{item.icon}}"></i>}
              <span> {{ item.label }}</span>
              @if(item.badge){<span
                class="badge rounded-pill float-end bg-{{item.badge.variant}}">{{item.badge.text}}</span>}
            </a>

            <ul class="sub-menu" aria-expanded="false">
              @for(subitem of item.vSubItems;track $index){
              <li>
                @if(hasItems(subitem)){
                <a class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId">
                  @if(subitem.icon){<i class="{{ subitem.icon }}"></i>}
                  <span>{{ subitem.label}}</span>
                  @if(subitem.badge){<span
                    class="badge rounded-pill bg-{{subitem.badge.variant}} float-end">{{subitem.badge.text}}</span>}
                </a>

                <ul class="sub-menu mm-collapse" aria-expanded="false">
                  @for(subSubitem of subitem.subItems;track $index){
                  <li>
                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"
                      class="side-nav-link-ref">
                      <span>{{ subSubitem.label }}</span>
                    </a>
                  </li>
                  }
                </ul>
                }@else {
                <a [routerLink]="subitem.link" class="side-nav-link-ref" [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active">
                  @if(subitem.icon){<i class="{{ subitem.icon }}"></i>}
                  <span>{{ subitem.label}}</span>
                  @if(subitem.badge){<span
                    class="badge rounded-pill bg-{{subitem.badge.variant}} float-end">{{subitem.badge.text}}</span>}
                </a>
                }
              </li>
              }
            </ul>
            }@else {
            <a [routerLink]="item.link" class="side-nav-link-ref" routerLinkActive="active">
              @if(item.icon){<i class="{{ item.icon }}"></i>}
              <span> {{ item.label }}</span>
              @if(item.badge){<span
                class="badge rounded-pill bg-{{item.badge.variant}} float-end">{{item.badge.text}}</span>}
            </a>
            }
          </li>
          }
          }
          }
        </ul>
      </div>
      <!-- Sidebar -->
    </ngx-simplebar>
  </div>
</div>
<!-- Left Sidebar End -->
