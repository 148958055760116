import { MenuItem } from "src/app/core/navegacao/models/menu.model";

export const MENU_CICLO_DE_VIDA: MenuItem[] = [
  {
    id: 1,
    label: 'Menu de navegação',
    isTitle: true
  },
  {
    id: 2,
    label: 'Resumo',
    icon: 'mdi mdi-view-dashboard-outline',
    link: '/lifecycle/resumo-ciclo-de-vida'
  },
];
