import { Component, OnInit, Input } from '@angular/core';
import { IconeBarraTarefasModel } from './models/icone-barra-tarefas-model';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})

/**
 * Page-Title Component
 */
export class PagetitleComponent implements OnInit {

  @Input()
  breadcrumbItems!: Array<{
    active?: boolean;
    label?: string;
  }>;

  @Input() title: string | undefined;
  @Input() toolbar?: Array<IconeBarraTarefasModel>;

  @Input() funcaoToolbar?: Function;

  constructor() { }

  ngOnInit(): void {
  }
}
