<!-- start page title -->
<app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row d-flex justify-content-center">
  <div class="col-md-6 col-xl-3">
    <div class="card cursor-pointer" [routerLink]="['/gerente/lifecycle-config/codigos']">
      <div class="card-body">
        <div class="float-end">
          <div class="avatar-sm mx-auto mb-4">
            <div>&nbsp;</div>
            <span class="avatar-title rounded-circle bg-light font-size-24">
              <i class="mdi mdi-file-code-outline text-primary"></i>
            </span>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <h4 class="mb-1 mt-1"><span>Códigos do Ciclo de Vida</span></h4>
        </div>
      </div>
    </div>
  </div> <!-- end col-->

  <div class="col-md-6 col-xl-3">
    <div class="card cursor-pointer" [routerLink]="['/gerente/lifecycle-config/fluxos-rpa']">
      <div class="card-body">
        <div class="float-end">
          <div class="avatar-sm mx-auto mb-4">
            <div>&nbsp;</div>
            <span class="avatar-title rounded-circle bg-light font-size-24">
              <i class="mdi mdi-robot text-success"></i>
            </span>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <h4 class="mb-1 mt-1"><span>Fluxos RPA</span></h4>
        </div>
      </div>
    </div>
  </div> <!-- end col-->
</div>