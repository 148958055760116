<div class="account-pages">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center">
                    <div>
                        <div class="row justify-content-center">
                            <div class="col-sm-5">
                                <div class="">
                                    <img src="assets/images/server-error.png" alt=""
                                        class="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 class="text-uppercase mt-4">Internal Server Error</h4>
                    <p class="text-muted">
                        It will be as simple as Occidental in fact, it will be Occidental
                    </p>
                    <div class="mt-5">
                        <a class="btn btn-primary waves-effect waves-light" routerLink="/"><i class="mdi mdi-home me-1"></i>Back to Dashboard</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>