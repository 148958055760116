import { MenuItem } from "src/app/core/navegacao/models/menu.model";

export const MENU_ESCRITORIOS: MenuItem[] = [
  {
    id: 2,
    isVertical: true,
    disabled: true,
    allowed: true,
    label: 'Perfis de acesso',
    icon: 'fa fas fa-users-cog',
    ishidden: true,
    vSubItems: [
      {
        id: 3,
        disabled: true,
        allowed: true,
        label: 'Meu perfil',
        link: '#',
        icon: 'fa fas fa-id-card-alt',
        parentId: 2
      },
      {
        id: 4,
        disabled: true,
        allowed: true,
        label: 'Perfis Colaboradores',
        link: '#',
        icon: 'fa fas fa-house-user',
        parentId: 2
      },
    ]
  },
  {
    id: 5,
    isVertical: true,
    disabled: true,
    allowed: true,
    label: 'Integração Domínio Sistemas',
    icon: 'fa fas fa-cogs',
    ishidden: true,
    vSubItems: [
      {
        id: 6,
        disabled: true,
        allowed: true,
        label: 'Contadore(s)',
        link: '/config/contador/contadores',
        icon: 'bx bxs-cog',
        parentId: 5
      },
      {
        id: 7,
        disabled: true,
        allowed: true,
        label: 'Escritório(s)',
        link: '/config/ds/escritorios',
        icon: 'bx bxs-cog',
        parentId: 5
      },
      {
        id: 8,
        disabled: true,
        allowed: true,
        label: 'Colaboradores',
        link: '/config/ds/colaboradores/configurar',
        icon: 'bx bxs-cog',
        parentId: 5
      },
      {
        id: 9,
        disabled: true,
        allowed: true,
        label: 'Empresas',
        link: '/config/ds/empresas/configurar',
        icon: 'bx bxs-cog',
        parentId: 5
      },
    ]
  },
  {
    id: 10,
    isVertical: true,
    disabled: true,
    allowed: true,
    label: 'Cargos e Departamentos',
    icon: 'fa fas fa-cogs',
    vSubItems: [
      {
        id: 11,
        disabled: true,
        allowed: true,
        label: 'Departamentos',
        link: '/config/organograma/departamentos',
        icon: 'bx bxs-cog',
        parentId: 10
      },
      {
        id: 12,
        disabled: true,
        allowed: true,
        label: 'Cargos',
        link: '/config/organograma/cargos',
        icon: 'bx bxs-cog',
        parentId: 10
      }
    ]
  }
];
