import { Component, OnInit } from '@angular/core';

// Swiper Sliderimport { SwiperOptions } from 'swiper';

import { latLng, tileLayer, circle, polygon, marker, icon, Layer } from 'leaflet';

import { BTCChart, ETHChart, LTCChart, balanceChart, marketChart, CurrentChart, DaiChart, Chart1, Chart2, Chart3, Chart4, Chart5, Chart6 } from './data';

@Component({
  selector: 'app-crypto',
  templateUrl: './crypto.component.html',
  styleUrls: ['./crypto.component.scss']
})

/**
 * Crypto Component
 */
export class CryptoComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  BTCChart: any;
  ETHChart: any;
  LTCChart: any;
  balanceChart: any;
  marketChart: any;
  CurrentChart: any;
  DailChart: any;
  Chart1: any;
  Chart2: any;
  Chart3: any;
  Chart4: any;
  Chart5: any;
  Chart6: any;


  constructor() { }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Samply' },
      { label: 'Crypto', active: true }
    ];

    // Chart Data Get
    this.BTCChart = BTCChart;
    this.ETHChart = ETHChart;
    this.LTCChart = LTCChart;
    this.balanceChart = balanceChart;
    this.marketChart = marketChart;
    this.CurrentChart = CurrentChart;
    this.DailChart = DaiChart
    this.Chart1 = Chart1;
    this.Chart2 = Chart2;
    this.Chart3 = Chart3;
    this.Chart4 = Chart4;
    this.Chart5 = Chart5;
    this.Chart6 = Chart6;
  }

  /**
   * Swiper Coverflow setting
   */
  public Coverflow = { "slidesToShow": 4, "slidesToScroll": 4 }

  /**
     * Choropleth Maps
     */
  choropleth = {
    layers: [
      tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw", {
        id: "mapbox/light-v9",
        tileSize: 512,
        zoomOffset: -1,
        maxZoom: 18,
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      })
    ],
    zoom: 1.1,
    center: latLng(28, 1.5)
  };
  choroplethLayers = [
    circle([41.9, 12.45], { color: "#435fe3", opacity: 0.5, weight: 10, fillColor: "#435fe3", fillOpacity: 1, radius: 400000, }),
    circle([12.05, -61.75], { color: "#435fe3", opacity: 0.5, weight: 10, fillColor: "#435fe3", fillOpacity: 1, radius: 400000, }),
    circle([1.3, 103.8], { color: "#435fe3", opacity: 0.5, weight: 10, fillColor: "#435fe3", fillOpacity: 1, radius: 400000, }),
  ];

}
