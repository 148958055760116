import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { EcommerceComponent } from './dashboard/ecommerce/ecommerce.component';
import { DashboardsComponent } from './dashboard/dashboards/dashboards.component';
import { StarterComponent } from './starter/starter.component';
import { CryptoComponent } from './dashboard/crypto/crypto.component';
import { AuthGuard } from '../core/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'starter', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'crypto',
    component: CryptoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'starter',
    component: StarterComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
